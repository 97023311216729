html,
body {
  margin: 0;
  height: 100%;
  /* overflow: hidden; */
}

.hub.card {
  min-height: 200px;
  margin: auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 20px;
  padding-top: 25px;
  background: none;
  border: 2px solid #ededed;
}

.card-body {
  padding: 1rem;
}

h1,
h1.hub {
  text-align: center;
  font-size: 2rem;
}

h2 {
  text-align: center;
  font-size: 0.25rem;
}

a,
a:hover {
  color: inherit;
}

.text {
  color: #ededed;
}

.backdrop {
  background-image: url("../../assets/valhalla_bay.png");
  background-size: cover;
  position: absolute;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.overlay {
  background-color: rgb(51, 51, 51, 0.5);
  height: 100vh;
  width: 100%;
}

.blur {
  z-index: 0;

  background-image: url("../../assets/valhalla_bay.png");
  background-size: cover;
  position: absolute;
  top: -10px;
  left: -10px;
  height: 110vh;
  width: 110vw;
  overflow: hidden;

  filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
  -moz-filter: blur(10px);
  -webkit-filter: blur(10px);

  padding: 0;
  margin: 0;
}
