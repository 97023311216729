.players {
  display: block;
  float: left;
  padding: 5px;
  overflow: scroll;
  height: 35vh;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.players::-webkit-scrollbar {
  display: none;
}

.players-list {
  padding: 10px;
}

.player {
  color: rgb(237, 237, 237, 0.5);
  cursor: pointer;
}

.player.online {
  color: #ededed;
}

.players .svg-inline--fa.fa-w-16 {
  width: 0.5em;
}
