

.user-mgmt {
  background: None;
  color: #d43030;
  border: None;
  padding: 10px;
  margin-top: -25px;
  margin-bottom: 10px;
}

.user-mgmt:hover {
  transform: scale(1.01);
  color: #d44030;
}