@import url(https://fonts.googleapis.com/css2?family=Lato&family=Merriweather:wght@300&display=swap);
.ControlHub {
  color: #ededed;
}

.ControlBar {
  border-bottom: 1px solid #ededed;
  padding-bottom: 25px;
}

.Menu {
  float: left;
  /* max-width: 750px !important */
}

.Menu li {
  float: left;
  list-style: none;
}

.Menu li button {
  width: 100%;
  /* border-radius: 0.25rem; */
  border-radius: 25px;
  padding: 10px;
  border: 1px solid #dfdfdf;
}

.Menu li button.profile-item {
  background: none;
  border: None;
}

.Menu .dropdown-menu {
  /* min-width: 0px; */
  top: 10px !important;
  background: rgb(237, 237, 237, 0.75);
}

.Menu .dropdown-item:hover {
  background-color: rgb(237, 237, 237, 0.55);
}

.profile-item {
  text-align: left;
}

.dropdown-toggle.btn-secondary,
.dropdown-toggle.btn-secondary:focus,
.dropdown-toggle.btn-secondary:active,
#map-button {
  /*
   *  The !importants here are technically bad practice, but the default styling
   #  set by bootstrap is so ingrained it's unpractical to overwrite them
   #  with specificity alone.
   */
  color: #ededed;
  background: none !important;
  border: 1px solid #ededed !important;
  box-shadow: none !important;
}

li button:disabled,
#map-button:disabled,
#dropdown-basic:disabled {
  color: rgb(237, 237, 237, 0.5);
  border: 1px solid rgb(237, 237, 237, 0.5) !important;
}

.dropdown-menu.show {
  min-width: 25rem;
  color: #ededed;
}

.dropdown .btn:hover {
  color: #ededed;
  text-decoration: none;
}

button {
  border-color: #6c757d;
}

button a,
button a:hover {
  color: inherit;
  text-decoration: inherit;
}

button.x {
  /* background: #aaa; */
  background: none;
}

button.x.in-progress,
ul li button.stop.light.in-progress,
ul li button.start.light.in-progress {
  /* background: #ededed; */
  background: none;
}

button.start {
  background: #6ead43;
}

button.start:hover {
  background: #6ebd43;
}

button.stop {
  background: #d43030;
}

button.stop:hover {
  background: #d44030;
}

.light {
  color: #ededed;
}

li {
  margin-top: 20px;
}

.Timer.col-12.col-md-6 {
  float: left;
  width: 100%;
  padding: 0;
}

/* @media screen and (min-width: 400px) {
  .Timer {
    float: right;
  }
} */

/* only on xs and sm */
.Timer div.d-block {
  float: left;
}

/* everything else */
.Timer div.d-none {
  float: right;
  /* padding-right: 50px; */
}

.timer-header {
  margin-right: 10px;
}

.countdown {
  width: 80px;
  display: inline-block;
  position: relative;
}

button.add-30 {
  width: 80px;
  min-width: 80px;
  border: none;
  background: none;
  color: #6ead43;
  position: absolute;
  left: 60px;
  bottom: -1px;
  overflow: visible;
  white-space: nowrap;
}

button.add-30:hover {
  transform: scale(1.01);
  color: #6ebd43;
}

button.add-30:disabled {
  transform: scale(1.01);
  color: #6ebd4378;
}

.PlayerStats {
  font-size: 0.8rem;
}

td {
  padding: 0px 10px;
}

.players {
  display: block;
  float: left;
  padding: 5px;
  overflow: scroll;
  height: 35vh;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.players::-webkit-scrollbar {
  display: none;
}

.players-list {
  padding: 10px;
}

.player {
  color: rgb(237, 237, 237, 0.5);
  cursor: pointer;
}

.player.online {
  color: #ededed;
}

.players .svg-inline--fa.fa-w-16 {
  width: 0.5em;
}

.requests {
  display: block;
  float: left;
  padding: 5px;
  overflow: scroll;
  min-height: 15vh;
  max-height: 30vh;
  width: 100%;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.requests::-webkit-scrollbar {
  display: none;
}

.requests-list {
  padding: 10px;
}

.request {
  color: #ededed;
}

.request.disabled {
  color: #ededed78;
}

.requests .svg-inline--fa.fa-w-16 {
  width: 0.9em;
  margin-right: 10px;
}

.requests .svg-inline--fa.fa-w-16.approve {
  color: #6ead43;
}

.requests .svg-inline--fa.fa-w-16.reject {
  color: #d43030;
}

.requests .svg-inline--fa.fa-w-16.approve.enabled:hover {
  color: #6ebd43;
}

.requests .svg-inline--fa.fa-w-16.reject.enabled:hover {
  color: #d44030;
}

.requests .svg-inline--fa.fa-w-16.enabled:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.requests .svg-inline--fa.fa-w-16.approve.disabled {
  color: #6ebd4378;  
}

.requests .svg-inline--fa.fa-w-16.reject.disabled {
  color: #d4303078;
}

.btn.close-message {
  border: none;
  display: inline;
  width: 10px;
  text-align: center;
  position: absolute;
  top: -5px;
  right: 5px;
}
.hub h1 {
  font-size: 2rem;
  letter-spacing: 3px;
  font-family: "Lato", san-serf;
}

.hub h2 {
  color: #ededed;
  font-size: 1rem;
  margin: 10px;
  font-weight: 200;
  letter-spacing: 2px;
  display: inline;
  text-align: center;
}

.subtext {
  text-align: center;
  margin: auto;
  width: 100%;
  text-align: center;
  display: block;
  margin-bottom: 2rem;
}

.subtext svg.svg-inline--fa.fa-w-16 {
  display: inline;
  text-align: center;
  width: 0.5em;
  margin: 0;
}

path,
svg {
  margin-right: 5px;
}

.ServerStatus > .text > .col-12 {
  padding-right: 0;
}

.server-status.col-12 {
  float: left;
  padding-left: 0;
  margin-left: -3px;
}

.hub a {
  float: left;
  text-decoration: none;
  color: #ededed;
}

.hub a:hover {
  text-decoration: none;
  color: #eee;
  transform: scale(1.01);
}

.info {
  width: 100%;
  padding: 10px 15px;
  float: left;
}

.info a svg {
  font-size: 20px;
  vertical-align: -0.225em;
}

/* .header {
  background-image: url(/static/media/pngwing.com.cdc945f6.png);
  background-position: top;
  background-repeat: no-repeat;
  background-size: 75%;
  height: 100px;
  padding: 10px;
} */

.texture-pack-download {
  clear: left;
}

.texture-pack-download.disabled {
  cursor: default;
  pointer-events: none;
  color: rgb(237, 237, 237, 0.5);
}

/* cheat to get download link to fetch after logging in */
.texture-pack-download.disabled.fetching {
  cursor: default;
  pointer-events: none;
  color: rgb(237, 237, 238, 0.5);
}

.texture-pack-download.disabled .fa-caret-right {
  color: rgb(237, 237, 237, 0.5);
}
.ControlHub.hub.card {
  padding-top: 5px;
}

.form-control.field-error {
  border: 1px solid #dc3545;
}

.msg.alert {
  margin-top: 10px;
  padding: 0.375rem 1.25rem;
}

button {
  width: 100%;
}

label {
  color: #ededed;
}

.btn-primary {
  background: none !important;
  border: 1px solid #ededed !important;
  margin-top: 50px;
  margin-bottom: 10px;
  border-radius: 25px !important;
}

input.form-control,
input.form-control:active,
input.form-control:focus {
  /* background: rgba(237, 237, 237, 0.5); */
  background: none;
  color: #ededed;
}

::-webkit-input-placeholder {
  /* Edge */
  color: #ededed;
}

::-webkit-input-placeholder,
:-ms-input-placeholder,
::placeholder {
  /* Edge */
  color: #ededed;
}



.user-mgmt {
  background: None;
  color: #d43030;
  border: None;
  padding: 10px;
  margin-top: -25px;
  margin-bottom: 10px;
}

.user-mgmt:hover {
  transform: scale(1.01);
  color: #d44030;
}
.btn.close-message {
  border: none;
  display: inline;
  width: 10px;
  text-align: center;
  position: absolute;
  top: -5px;
  right: 5px;
}

h1,
h1.hub .ChangePassword > h1 {
  text-align: center;
  font-size: 2rem;
  color: #ededed;
}

.btn.close-message {
  border: none;
  display: inline;
  width: 10px;
  text-align: center;
  position: absolute;
  top: -5px;
  right: 5px;
}

h1,
h1.hub .ChangePassword > h1 {
  text-align: center;
  font-size: 2rem;
  color: #ededed;
}

.nevermind {
  background: None;
  color: #d43030;
  border: None;
  padding: 10px;
  margin-top: -25px;
}

.nevermind:hover {
  transform: scale(1.01);
  color: #d44030;
}
.home {
  position: absolute;
  width: 300px;
  background: none;
  top: 75px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.home button {
  background: none;
  color: #fff;
  border: none;
  font-size: 2rem;
  letter-spacing: 3px;
  font-family: "Lato", san-serf;
}

.home:hover {
  transform: scale(1.01);
}

.btn.close-message {
  border: none;
  display: inline;
  width: 10px;
  text-align: center;
  position: absolute;
  top: -5px;
  right: 5px;
}

h1,
h1.hub .ChangePassword > h1 {
  text-align: center;
  font-size: 2rem;
  color: #ededed;
}

.nevermind {
  background: None;
  color: #d43030;
  border: None;
  padding: 10px;
  margin-top: -25px;
}

.nevermind:hover {
  transform: scale(1.01);
  color: #d44030;
}
html,
body {
  margin: 0;
  height: 100%;
  /* overflow: hidden; */
}

.hub.card {
  min-height: 200px;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 20px;
  padding-top: 25px;
  background: none;
  border: 2px solid #ededed;
}

.card-body {
  padding: 1rem;
}

h1,
h1.hub {
  text-align: center;
  font-size: 2rem;
}

h2 {
  text-align: center;
  font-size: 0.25rem;
}

a,
a:hover {
  color: inherit;
}

.text {
  color: #ededed;
}

.backdrop {
  background-image: url(/static/media/valhalla_bay.2454b737.png);
  background-size: cover;
  position: absolute;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.overlay {
  background-color: rgb(51, 51, 51, 0.5);
  height: 100vh;
  width: 100%;
}

.blur {
  z-index: 0;

  background-image: url(/static/media/valhalla_bay.2454b737.png);
  background-size: cover;
  position: absolute;
  top: -10px;
  left: -10px;
  height: 110vh;
  width: 110vw;
  overflow: hidden;

  filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
  -moz-filter: blur(10px);
  -webkit-filter: blur(10px);

  padding: 0;
  margin: 0;
}

