.requests {
  display: block;
  float: left;
  padding: 5px;
  overflow: scroll;
  min-height: 15vh;
  max-height: 30vh;
  width: 100%;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.requests::-webkit-scrollbar {
  display: none;
}

.requests-list {
  padding: 10px;
}

.request {
  color: #ededed;
}

.request.disabled {
  color: #ededed78;
}

.requests .svg-inline--fa.fa-w-16 {
  width: 0.9em;
  margin-right: 10px;
}

.requests .svg-inline--fa.fa-w-16.approve {
  color: #6ead43;
}

.requests .svg-inline--fa.fa-w-16.reject {
  color: #d43030;
}

.requests .svg-inline--fa.fa-w-16.approve.enabled:hover {
  color: #6ebd43;
}

.requests .svg-inline--fa.fa-w-16.reject.enabled:hover {
  color: #d44030;
}

.requests .svg-inline--fa.fa-w-16.enabled:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.requests .svg-inline--fa.fa-w-16.approve.disabled {
  color: #6ebd4378;  
}

.requests .svg-inline--fa.fa-w-16.reject.disabled {
  color: #d4303078;
}

.btn.close-message {
  border: none;
  display: inline;
  width: 10px;
  text-align: center;
  position: absolute;
  top: -5px;
  right: 5px;
}