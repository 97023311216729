.form-control.field-error {
  border: 1px solid #dc3545;
}

.msg.alert {
  margin-top: 10px;
  padding: 0.375rem 1.25rem;
}

button {
  width: 100%;
}

label {
  color: #ededed;
}

.btn-primary {
  background: none !important;
  border: 1px solid #ededed !important;
  margin-top: 50px;
  margin-bottom: 10px;
  border-radius: 25px !important;
}

input.form-control,
input.form-control:active,
input.form-control:focus {
  /* background: rgba(237, 237, 237, 0.5); */
  background: none;
  color: #ededed;
}

::-webkit-input-placeholder,
:-ms-input-placeholder,
::placeholder {
  /* Edge */
  color: #ededed;
}
