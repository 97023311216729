.btn.close-message {
  border: none;
  display: inline;
  width: 10px;
  text-align: center;
  position: absolute;
  top: -5px;
  right: 5px;
}

h1,
h1.hub .ChangePassword > h1 {
  text-align: center;
  font-size: 2rem;
  color: #ededed;
}

.nevermind {
  background: None;
  color: #d43030;
  border: None;
  padding: 10px;
  margin-top: -25px;
}

.nevermind:hover {
  transform: scale(1.01);
  color: #d44030;
}